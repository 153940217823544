import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "bp-license" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_translate = _resolveComponent("translate")!
  const _component_bp_view_header = _resolveComponent("bp-view-header")!
  const _component_bp_customer_license_information = _resolveComponent("bp-customer-license-information")!
  const _component_bp_masonry_grid = _resolveComponent("bp-masonry-grid")!
  const _component_bp_button = _resolveComponent("bp-button")!
  const _component_bp_tooltip = _resolveComponent("bp-tooltip")!
  const _component_bp_card = _resolveComponent("bp-card")!
  const _component_bp_select = _resolveComponent("bp-select")!
  const _component_bp_form_button = _resolveComponent("bp-form-button")!
  const _component_bp_window = _resolveComponent("bp-window")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_bp_view_header, {
      class: "bp-license__header",
      image: "/static/background/app_background_license.jpg",
      icon: ['fad', 'file-certificate']
    }, {
      default: _withCtx(() => [
        _createVNode(_component_translate, null, {
          default: _withCtx(() => _cache[2] || (_cache[2] = [
            _createTextVNode("License")
          ])),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_bp_card, {
      "fill-height": "",
      "fill-width": ""
    }, {
      footer: _withCtx(() => [
        _createVNode(_component_bp_tooltip, {
          condition: _ctx.user.roles.includes('impersonate_user'),
          tooltip: _ctx.$gettext('Not available as impersonated user')
        }, {
          default: _withCtx(() => [
            _createVNode(_component_bp_button, {
              disabled: _ctx.user.roles.includes('impersonate_user'),
              icon: ['far', 'file-certificate'],
              onClick: _ctx.show
            }, {
              default: _withCtx(() => [
                _createVNode(_component_translate, null, {
                  default: _withCtx(() => _cache[3] || (_cache[3] = [
                    _createTextVNode("Renew license")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["disabled", "onClick"])
          ]),
          _: 1
        }, 8, ["condition", "tooltip"]),
        _createVNode(_component_bp_button, {
          icon: ['far', 'file-pdf'],
          onClick: _ctx.generateCertificate
        }, {
          default: _withCtx(() => [
            _createVNode(_component_translate, null, {
              default: _withCtx(() => _cache[4] || (_cache[4] = [
                _createTextVNode("Generate license certificate")
              ])),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["onClick"])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_bp_masonry_grid, { columns: _ctx.columns }, {
          default: _withCtx(() => [
            (_ctx.customer)
              ? (_openBlock(), _createBlock(_component_bp_customer_license_information, {
                  key: 0,
                  "model-value": _ctx.customer,
                  "hide-unlicensed-products": ""
                }, null, 8, ["model-value"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["columns"])
      ]),
      _: 1
    }),
    _createVNode(_component_bp_window, {
      class: "bp-license__window",
      modelValue: _ctx.confirmation,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.confirmation) = $event))
    }, {
      header: _withCtx(() => [
        _createVNode(_component_translate, null, {
          default: _withCtx(() => _cache[5] || (_cache[5] = [
            _createTextVNode("Confirm")
          ])),
          _: 1
        })
      ]),
      footer: _withCtx(() => [
        _createVNode(_component_bp_form_button, {
          icon: ['far', 'envelope'],
          color: "green",
          action: _ctx.sendEmail
        }, {
          default: _withCtx(() => [
            _createVNode(_component_translate, null, {
              default: _withCtx(() => _cache[7] || (_cache[7] = [
                _createTextVNode("Confirm")
              ])),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["action"]),
        _createVNode(_component_bp_form_button, {
          type: "cancel",
          action: _ctx.hide
        }, null, 8, ["action"])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_translate, null, {
          default: _withCtx(() => _cache[6] || (_cache[6] = [
            _createTextVNode("When you click \"Confirm\", a request will be sent to a responsible employee.")
          ])),
          _: 1
        }),
        _createVNode(_component_bp_select, {
          data: _ctx.products,
          label: _ctx.$gettext('Products'),
          "search-property": `name.${_ctx.currentLanguageISOString()}`,
          "label-position": "top",
          multiple: "",
          modelValue: _ctx.selectedProducts,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedProducts) = $event))
        }, null, 8, ["data", "label", "search-property", "modelValue"])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}