
import { byKey } from '@/utils/array';
import { currentLanguage, currentLanguageISOString } from '@/translation';
import { Customer, useCustomerStore } from '@/stores/customer';
import { defineComponent, ref, watch } from 'vue';
import { json, url } from '@sahnee/ajax';
import { Product, useVersionStore } from '@/stores/version';
import { useUser } from '@/utils/user';
import BpCustomerLicenseInformationVue from '@/components/customer/BpCustomerLicenseInformation.vue';
import BpToast from '@/components/toast/BpToasts';
import { $gettext } from 'vue-gettext';

export default defineComponent({
  name: 'License',
  components: {
    BpCustomerLicenseInformation: BpCustomerLicenseInformationVue,
  },
  setup() {
    ///-------------------------------------------------------------------
    /// STORE
    ///-------------------------------------------------------------------

    const customerStore = useCustomerStore();
    const versionStore = useVersionStore();

    ///-------------------------------------------------------------------
    /// USER
    ///-------------------------------------------------------------------

    const user = useUser();

    ///-------------------------------------------------------------------
    /// CUSTOMER
    ///-------------------------------------------------------------------

    const customer = ref<Customer>();

    watch(() => user.value.company, async () => {
      if (!user.value.company) {
        return;
      }
      const response = await customerStore.readById(user.value.company || '');
      if (response && response.success) {
        customer.value = response.data;
      }
    }, { immediate: true })

    ///-------------------------------------------------------------------
    /// CONFIRMATION
    ///-------------------------------------------------------------------

    const confirmation = ref(false);

    function show() {
      confirmation.value = true;
    }

    function hide() {
      confirmation.value = false;
    }

    async function sendEmail() {
      const response = await json<{ success: boolean, error?: string }>('/api/mail', {
        method: 'POST',
        json: {
          companyId: user.value.company,
          userId: user.value._id,
          language: currentLanguage.value,
          action: 'renew',
          licenseType: customer.value?.subscription ? 'subscription' : 'support',
          products: selectedProducts.value,
        }
      });
      if (response.success) {
        BpToast.show({
          color: 'green',
          title: $gettext('License renewal request success'),
          content: $gettext('Requesting license renewal was successfull. An employee will contact you shortly.'),
          icon: 'circle-check',
        });
        hide();
      } else {
        BpToast.show({
          color: 'red',
          title: $gettext('License renewal request failed'),
          content: $gettext('Requesting license renewal failed.'),
          icon: 'triangle-exclamation',
        });
      }
    }

    async function generateCertificate() {
      window.open(
        url({
          url:'/api/get/certificate',
          search: {
            customer: window.btoa(JSON.stringify(customer.value)),
            date: new Date().toISOString(),
            email: user.value.email
          }
        })
      )
    }

    ///-------------------------------------------------------------------
    /// VERSION
    ///-------------------------------------------------------------------

    const products = ref<Product[]>();
    const selectedProducts = ref<string[]>([])

    watch(() => [versionStore.isLoading(), customer.value], () => {
      if (!versionStore.loaded || versionStore.isLoading() || !customer.value) { return }

      const found = [];
      for (const productId of ['bestinformed', 'bestproxy', 'bestzero']) {
        if (customerStore.hasProduct(customer.value, productId)) {
          const product = versionStore.findVersionByName(productId) as Product;
          if (!product) { continue }
          found.push(product);
        }
      }
      selectedProducts.value = found.map(product => product._id);
      products.value = found;
    }, { immediate: true })

    ///-------------------------------------------------------------------
    /// COLUMNS
    ///-------------------------------------------------------------------
    
    const columns = ref();

    watch(() => [versionStore.loaded, customerStore.loaded, customer.value], () => {
      const c = customer.value;
      if (!c) return;
      columns.value = versionStore.getRootProducts().sort(byKey('uuid')).filter(product => customerStore.hasProduct(c, product.uuid)).length > 1 ? 2 : 1;
    }, { immediate: true })

    ///-------------------------------------------------------------------
    /// RETURN
    ///-------------------------------------------------------------------

    return {
      columns,
      confirmation,
      currentLanguageISOString,
      customer,
      generateCertificate,
      hide,
      products,
      selectedProducts,
      sendEmail,
      show,
      user,
    }
  }
});
